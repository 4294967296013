import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Windows/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/CCTV_Chrome_Plugin/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "Use the CCTV Chrome Browser Plugin to control your camera from your favourite web browser.",
  "image": "./P_SearchThumb_CCTV.png",
  "social": "/images/Search/P_SearchThumb_CCTV.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-CCTV_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='CCTV' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the CCTV Chrome Browser Plugin to control your camera from your favourite web browser.' image='/images/Search/P_SearchThumb_CCTV.png' twitter='/images/Search/P_SearchThumb_CCTV.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/CCTV_Chrome_Plugin/' locationFR='/fr/Software/Windows/CCTV_Chrome_Plugin/' crumbLabel="CCTV" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h2 {...{
      "id": "cctv-browser-plugin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cctv-browser-plugin",
        "aria-label": "cctv browser plugin permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CCTV Browser Plugin`}</h2>
    <p>{`This Google Chrome / Opera extension allows you to define up to 16 IP cameras or webcam urls. Every time the toolbar button is clicked it will show an updated version of the image.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/004c77d8ac678a03ed864ea93ed6964c/c08c5/CCTV_View_Google_Chrome_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAezOkgH/xAAYEAADAQEAAAAAAAAAAAAAAAAAARECMf/aAAgBAQABBQKGdrRRuJch/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAABAxIf/aAAgBAQAGPwKGOL//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEQMUFh/9oACAEBAAE/IbcFSwBrdkPBgAaAs4QpP//aAAwDAQACAAMAAAAQAM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhQTFxof/aAAgBAQABPxDakbWvKiAS5iioSWlEZAe4W3NmCAaGf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/004c77d8ac678a03ed864ea93ed6964c/e4706/CCTV_View_Google_Chrome_01.avif 230w", "/en/static/004c77d8ac678a03ed864ea93ed6964c/d1af7/CCTV_View_Google_Chrome_01.avif 460w", "/en/static/004c77d8ac678a03ed864ea93ed6964c/e6133/CCTV_View_Google_Chrome_01.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/004c77d8ac678a03ed864ea93ed6964c/a0b58/CCTV_View_Google_Chrome_01.webp 230w", "/en/static/004c77d8ac678a03ed864ea93ed6964c/bc10c/CCTV_View_Google_Chrome_01.webp 460w", "/en/static/004c77d8ac678a03ed864ea93ed6964c/0ba47/CCTV_View_Google_Chrome_01.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/004c77d8ac678a03ed864ea93ed6964c/e83b4/CCTV_View_Google_Chrome_01.jpg 230w", "/en/static/004c77d8ac678a03ed864ea93ed6964c/e41a8/CCTV_View_Google_Chrome_01.jpg 460w", "/en/static/004c77d8ac678a03ed864ea93ed6964c/c08c5/CCTV_View_Google_Chrome_01.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/004c77d8ac678a03ed864ea93ed6964c/c08c5/CCTV_View_Google_Chrome_01.jpg",
              "alt": "CCTV Google Chrome Plugin for your INSTAR IP Camera",
              "title": "CCTV Google Chrome Plugin for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In order to integrate your INSTAR IP camera in `}<a href="http://www.cctvview.info/CCTV_View" target="_blank" rel="noopener noreferrer">{`CCTV View`}</a>{` we recommend you to download the latest version for `}<a href="https://chrome.google.com/webstore/detail/cctv-view/oajmcmcpiboagipoflploplebgicaadj/details?hl=en">{`Google Chrome`}</a>{` or `}<a href="https://addons.opera.com/en/extensions/details/cctv-view/?display=en">{`Opera`}</a>{`.`}</p>
    <h3 {...{
      "id": "add-a-new-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-new-camera",
        "aria-label": "add a new camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a New Camera`}</h3>
    <p><strong parentName="p">{`Step 1`}</strong>{`: You can download the free Google Chrome extension here. After installing the extension start the user interface (s. below) by opening the `}<strong parentName="p">{`Options`}</strong>{` page and choosing `}<strong parentName="p">{`Extensions`}</strong>{`. Choose Options again to get to the advanced controls.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/c08c5/CCTV_View_Google_Chrome_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.478260869565215%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAFABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAEDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHVvFgJ/8QAFxABAAMAAAAAAAAAAAAAAAAAAQIQIf/aAAgBAQABBQIlg1//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwFn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFRABAQAAAAAAAAAAAAAAAAAAERD/2gAIAQEABj8CS//EABcQAQEBAQAAAAAAAAAAAAAAAAARQYH/2gAIAQEAAT8h7xSNf//aAAwDAQACAAMAAAAQ88//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QGM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAACAwADAAAAAAAAAAAAAAABEQAhUTGh0f/aAAgBAQABPxDlgYMUVnsoEq2EBq7M/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/e4706/CCTV_View_Google_Chrome_02.avif 230w", "/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/d1af7/CCTV_View_Google_Chrome_02.avif 460w", "/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/e6133/CCTV_View_Google_Chrome_02.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/a0b58/CCTV_View_Google_Chrome_02.webp 230w", "/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/bc10c/CCTV_View_Google_Chrome_02.webp 460w", "/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/0ba47/CCTV_View_Google_Chrome_02.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/e83b4/CCTV_View_Google_Chrome_02.jpg 230w", "/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/e41a8/CCTV_View_Google_Chrome_02.jpg 460w", "/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/c08c5/CCTV_View_Google_Chrome_02.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/44b64dbc2a29b4b3ff0cec46cfdda69e/c08c5/CCTV_View_Google_Chrome_02.jpg",
              "alt": "CCTV Google Chrome Plugin for your INSTAR IP Camera",
              "title": "CCTV Google Chrome Plugin for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Please enter your cameras video stream path as "Image URL" and choose a channel name as "Target URL" and attach your login credentials. E.g. use the following URL if your camera has the local IP address 192.168.1.101, uses the HTTP port 85 and you have the default login user = admin and password = «empty»:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`MJPEG URL: http://192.168.178.172:85/videostream.cgi?user=admin&pwd=`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "445px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8fac5206348220c814b17b82965c3b5b/0a4fe/CCTV_View_Google_Chrome_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdlOUoAf/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAFBEBH/2gAIAQEAAQUCiyI4f//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQEBAQAAAAAAAAAAAAAAAABBARD/2gAIAQEABj8CVW8j/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERMfEhcf/aAAgBAQABPyF2JYO4GCSqR4H/2gAMAwEAAgADAAAAELQv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QZ//EAB4QAQACAgEFAAAAAAAAAAAAAAEAEUFRIWGRocHh/9oACAEBAAE/EBSl2dIq2PLQ9xd+1NbzFsyZVi/sz//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8fac5206348220c814b17b82965c3b5b/e4706/CCTV_View_Google_Chrome_03.avif 230w", "/en/static/8fac5206348220c814b17b82965c3b5b/664a0/CCTV_View_Google_Chrome_03.avif 445w"],
              "sizes": "(max-width: 445px) 100vw, 445px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8fac5206348220c814b17b82965c3b5b/a0b58/CCTV_View_Google_Chrome_03.webp 230w", "/en/static/8fac5206348220c814b17b82965c3b5b/75680/CCTV_View_Google_Chrome_03.webp 445w"],
              "sizes": "(max-width: 445px) 100vw, 445px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8fac5206348220c814b17b82965c3b5b/e83b4/CCTV_View_Google_Chrome_03.jpg 230w", "/en/static/8fac5206348220c814b17b82965c3b5b/0a4fe/CCTV_View_Google_Chrome_03.jpg 445w"],
              "sizes": "(max-width: 445px) 100vw, 445px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8fac5206348220c814b17b82965c3b5b/0a4fe/CCTV_View_Google_Chrome_03.jpg",
              "alt": "CCTV Google Chrome Plugin for your INSTAR IP Camera",
              "title": "CCTV Google Chrome Plugin for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Enter your camera´s video URL and attach your login credentials.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: Click the CCTV icon in your extension bar to start the live streams. Or click on "Monitor Page" to open the streams in a new window.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b5db356472fb1177d425e964f5d768b/7cc5e/CCTV_View_Google_Chrome_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "96.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB310YDtBNMBLAf//EABsQAAMAAwEBAAAAAAAAAAAAAAECAwAEExEz/9oACAEBAAEFAneM27a2Cc2Gx525qWj8DNWPGeAAD//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwEr/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHRAAAwACAgMAAAAAAAAAAAAAAAERAjEiMkJRcf/aAAgBAQAGPwKZRM8RObHfQ+UiMPhXimdERaP/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFRkWH/2gAIAQEAAT8heN1KjzOXyFUULKWAvbIxUb9T6QadTdEdpF9+cFAAwBP/2gAMAwEAAgADAAAAECDQ/wD/xAAXEQEBAQEAAAAAAAAAAAAAAAABABAh/9oACAEDAQE/EJUvc//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxAj/8QAGxABAQEBAQADAAAAAAAAAAAAAREAMSFxgZH/2gAIAQEAAT8QAEQXrHnDGQoF65+ZmyxjT7c8CTsE4+5lQRBl8WBiBtBl+XeUZnoZk61kqcAdYEA3/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b5db356472fb1177d425e964f5d768b/e4706/CCTV_View_Google_Chrome_04.avif 230w", "/en/static/9b5db356472fb1177d425e964f5d768b/d1af7/CCTV_View_Google_Chrome_04.avif 460w", "/en/static/9b5db356472fb1177d425e964f5d768b/884e7/CCTV_View_Google_Chrome_04.avif 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b5db356472fb1177d425e964f5d768b/a0b58/CCTV_View_Google_Chrome_04.webp 230w", "/en/static/9b5db356472fb1177d425e964f5d768b/bc10c/CCTV_View_Google_Chrome_04.webp 460w", "/en/static/9b5db356472fb1177d425e964f5d768b/d3be9/CCTV_View_Google_Chrome_04.webp 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b5db356472fb1177d425e964f5d768b/e83b4/CCTV_View_Google_Chrome_04.jpg 230w", "/en/static/9b5db356472fb1177d425e964f5d768b/e41a8/CCTV_View_Google_Chrome_04.jpg 460w", "/en/static/9b5db356472fb1177d425e964f5d768b/7cc5e/CCTV_View_Google_Chrome_04.jpg 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b5db356472fb1177d425e964f5d768b/7cc5e/CCTV_View_Google_Chrome_04.jpg",
              "alt": "CCTV Google Chrome Plugin for your INSTAR IP Camera",
              "title": "CCTV Google Chrome Plugin for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And you are up and running.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      